import './App.css';

function App() {
  return (
    <div className="App">
      <div className="App-container">
        <header className="App-header">        
          <div className="App-title">
            <span className="App-name noselect">🔔 Notif</span>
            <span className="App-logo">
              <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 1000 1000">
                <defs>
                  <style>{`.cls-1 { fill: #2c3e50; stroke-width: 0px; }`}</style>
                </defs>
                <path className="cls-1" d="M500,275c-124.3,0-225,100.7-225,225s100.7,225,225,225,225-100.7,225-225-100.7-225-225-225ZM500,650c-82.8,0-150-67.2-150-150s67.2-150,150-150,150,67.2,150,150-67.2,150-150,150Z"/>
                <path className="cls-1" d="M722.7,711.4l-16.7-62.6c-2-7.4-11.2-9.9-16.5-4.5l-45.7,45.7c-5.4,5.4-3,14.5,4.5,16.5l62.6,16.7c7.1,2.1,13.9-4.6,11.9-11.9h0Z"/>
              </svg>
            </span>
          </div>
        </header>
        <div className="App-body">
          <div className="App-message-box noselect">
              Your personalized notification queue.
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
